import React from 'react'
import { Link } from 'react-router-dom'
import logo from './ztree.png';
import ig from './insta.png';
import yt from './youtube.png';

export default function HomePage() {
  return (
	    <div className="App">
		     
			 <div className="Card">
		      <div className="Upload">
		        <div className="Content">
		        	<img src={logo}/>
		        </div>
		       </div>
		      </div>
		      
		     
		      
		  </div>
  )
}